import Spacing from '../Spacing';
import Lottie from 'react-lottie';
import loading from '../../loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const diseases = [
    {
        title: 'Cataract'
    },
    {
        title: 'Stones in biliary and urinary systems'
    },
    {
        title: 'Hernia / Hydrocele'
    },
    {
        title: 'Hysterectomy for any benign disorder'
    },
    {
        title: 'Lumps / cysts / nodules / polyps / internal tumours'
    },
    {
        title: 'Gastric and Duodenal Ulcers'
    },
    {
        title: 'Surgery on tonsils / adenoids'
    },
    {
        title: 'Osteoarthritis / Arthritis / Gout / Rheumatism / Spondylosis / Spondylitis / Intervertebral Disc Prolapse'
    },
    {
        title: 'Fissure / Fistula / Haemorrhoid'
    },
    {
        title: 'Sinusitis / Deviated Nasal Septum / Tympanoplasty / Chronic Suppurative Otitis Media'
    },
    {
        title: 'Benign Prostatic Hypertrophy'
    },
    {
        title: 'Knee/Hip Joint replacement'
    },
    {
        title: 'Dilatation and Curettage'
    },
    {
        title: 'Varicose veins'
    },
    {
        title: 'Dysfunctional Uterine Bleeding / Fibroids / Prolapse Uterus / Endometriosis'
    },
    {
        title: 'Chronic Renal Failure or end stage Renal Failure'
    },
    {
        title: 'Internal congenital anomalies/diseases/defects'
    },
    {
        title: 'HIV , AIDS'
    },
];

export default function StepFour({
    error,
    loading,
    setIsSpecificIllness,
    goToStepPlan
}) {

    return (
        <>
            <div className="cs_list cs_style_1">
                <h2 className="cs_list_title cs_medium cs_fs_24" style={{ paddingLeft: '0px' }}>
                    Please verify if the condition is among those listed below:
                </h2>
                <ul>
                    <div className='row'>
                        {diseases?.map((item, index) => (
                            <li key={index} className='col-12 col-lg-6'>
                                {item.title && (
                                    <p className="cs_medium cs_heading_color mb-0">{item.title}</p>
                                )}
                            </li>
                        ))}
                    </div>
                </ul>
            </div>
            {/* <Spacing md="20" /> */}
            <div className="col-lg-12">
                <div className="cs_radio_group row" style={{ padding: 'calc(var(--bs-gutter-x)* .5)' }}>
                    <div className="cs_radio_wrap">
                        <input
                            className="cs_radio_input"
                            type="radio"
                            name="isSpecificIllness"
                            id="no"
                            value={'NO'}
                            onChange={(a) => setIsSpecificIllness(a.target.value)}
                        />
                        <label className="cs_radio_label" htmlFor="no">
                            I / we do not have any of these 18 conditions currently.
                        </label>
                    </div>
                    <div className="cs_radio_wrap">
                        <input
                            className="cs_radio_input"
                            type="radio"
                            name="isSpecificIllness"
                            id="yes"
                            value={'YES'}
                            onChange={(a) => setIsSpecificIllness(a.target.value)}
                        />
                        <label className="cs_radio_label" htmlFor="yes">
                            I / we do have one or many of these 18 conditions currently.
                        </label>
                    </div>
                </div>
                <div className="cs_height_10" />
            </div>
            <div>
                By proceeding, I affirm that all information given is correct and truthful to the best of my knowledge.
            </div>
            <Spacing md="20" />
            {loading ? (
                <button className="cs_btn cs_style_1">
                    <span>Continue</span>
                    <Lottie options={defaultOptions} width={45} />
                </button>
            ) : (
                <button className="cs_btn cs_style_1" onClick={goToStepPlan}>
                    <span>Continue</span>
                    <i>
                        <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                        <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                    </i>
                </button>
            )}
            {error &&
                <div style={{ color: 'red', fontSize: '12px' }}>
                    Please complete the details
                </div>
            }
        </>
    );
}