import { useState } from 'react';
// import { Icon } from '@iconify/react';
// import DatePicker from 'react-datepicker';
import ReactSlider from 'react-slider';
import Spacing from '../Spacing';
import Lottie from 'react-lottie';
import loading from '../../loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const points = [3, 4.5, 5, 6];

export default function StepOne({
    error,
    loading,
    setInsuredValue,
    policyNumber,
    setPolicyNumber,
    purchaseDate,
    setPurchaseDate,
    validUpto,
    setValidUpto,
    companyName,
    setCompanyName,
    deductable,
    setDeductable,
    goToStepTwo
}) {
    const [existingDetails, setExistingDetails] = useState(false);

    const setInsured = (value) => {
        setInsuredValue(value)
        if (value == 'YES') {
            setExistingDetails(true)
        } else {
            setExistingDetails(false)
        }
    }

    const findNearestPoint = (value) => {
        let closest = points.reduce((prev, curr) => {
            return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
        });
        return closest;
    };

    const handleChange = (value) => {
        setDeductable(findNearestPoint(value));
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <label className="cs_input_label cs_heading_color">
                        Please indicate if you currently hold any form of insurance coverage:
                    </label>
                    <div className="cs_radio_group">
                        <div className="cs_radio_wrap">
                            <input
                                className="cs_radio_input"
                                type="radio"
                                name="insured"
                                id="yes"
                                value={'YES'}
                                onChange={(a) => setInsured(a.target.value)}
                            />
                            <label className="cs_radio_label" htmlFor="yes">
                                Yes
                            </label>
                        </div>
                        <div className="cs_radio_wrap">
                            <input
                                className="cs_radio_input"
                                type="radio"
                                name="insured"
                                id="no"
                                value={'NO'}
                                onChange={(a) => setInsured(a.target.value)}
                            />
                            <label className="cs_radio_label" htmlFor="no">
                                No
                            </label>
                        </div>
                    </div>
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                {existingDetails &&
                    <>
                        {/* <div className="col-lg-12">
                            <label className="cs_input_label cs_heading_color">
                                Policy Number
                            </label>
                            <input
                                type="text"
                                className="cs_form_field"
                                placeholder="123456-7890-0987"
                                value={policyNumber}
                                onChange={(a) => setPolicyNumber(a.target.value)}
                            />
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div>
                        <div className="col-lg-6">
                            <label className="cs_input_label cs_heading_color">
                                Purchase Date
                            </label>
                            <div className="cs_with_icon_input">
                                <DatePicker
                                    selected={purchaseDate}
                                    onChange={date => setPurchaseDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    // minDate={new Date()}
                                    isClearable
                                    placeholderText="dd/mm/yyyy"
                                />
                                <i>
                                    <Icon icon="fa6-solid:calendar-days" />
                                </i>
                            </div>
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div>
                        <div className="col-lg-6">
                            <label className="cs_input_label cs_heading_color">
                                Valid Upto
                            </label>
                            <div className="cs_with_icon_input">
                                <DatePicker
                                    selected={validUpto}
                                    onChange={date => setValidUpto(date)}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    isClearable
                                    placeholderText="dd/mm/yyyy"
                                />
                                <i>
                                    <Icon icon="fa6-solid:calendar-days" />
                                </i>
                            </div>
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div>
                        <div className="col-lg-6">
                            <label className="cs_input_label cs_heading_color">Company Name</label>
                            <input
                                value={companyName}
                                onChange={(a) => setCompanyName(a.target.value)}
                                type="text"
                                className="cs_form_field"
                                placeholder="Company Name" />
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div> */}
                        <div className="col-lg-6">
                            <label className="cs_input_label cs_heading_color" style={{ marginBottom: '25px' }}>
                                What is the value of your current insurance policy?
                            </label>
                            <ReactSlider
                                defaultValue={deductable}
                                min={Math.min(...points)}
                                max={Math.max(...points)}
                                step={1}
                                onChange={handleChange}
                                renderTrack={({ style, ...props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...style,
                                            height: '2px',
                                            background: 'rgba(210, 83, 140, 0.5)'
                                        }}
                                    />
                                )}
                                renderThumb={({ style, ...props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...style,
                                            height: '25px',
                                            width: '25px',
                                            backgroundColor: '#D2538C',
                                            borderRadius: '50%',
                                            top: '-12.5px',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >{deductable}</div>
                                )}
                                withTracks={true}
                            />
                            <div style={{ color: '#D2538C', marginTop: '25px', fontWeight: 'bold' }}>{deductable} Lakhs</div>
                            <div className="cs_input_label cs_heading_color mt-4">
                                The above chosen amount is the minimum deductible from you.
                            </div>
                        </div>
                    </>
                }
            </div>
            <Spacing md="20" />
            {loading ? (
                <button className="cs_btn cs_style_1">
                    <span>Continue</span>
                    <Lottie options={defaultOptions} width={45} />
                </button>
            ) : (
                <button className="cs_btn cs_style_1" onClick={goToStepTwo}>
                    <span>Continue</span>
                    <i>
                        <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                        <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                    </i>
                </button>
            )}
            {error &&
                <div style={{ color: 'red', fontSize: '12px' }}>
                    Please complete the details
                </div>
            }
        </>
    );
}