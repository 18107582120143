import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      {/* <h2>Follow Us</h2> */}
      <div className="cs_social_links">
        <Link to="#" style={{ backgroundColor: '#D2538C', width: '40px', height: '40px' }}>
          <Icon icon="fa-brands:facebook-f" style={{ color: '#fff', fontSize: '18px' }} />
        </Link>
        <Link to="#" style={{ backgroundColor: '#D2538C', width: '40px', height: '40px' }}>
          <Icon icon="fa-brands:youtube" style={{ color: '#fff', fontSize: '18px' }} />
        </Link>
        <Link to="#" style={{ backgroundColor: '#D2538C', width: '40px', height: '40px' }}>
          <Icon icon="fa-brands:linkedin-in" style={{ color: '#fff', fontSize: '18px' }} />
        </Link>
        <Link to="#" style={{ backgroundColor: '#D2538C', width: '40px', height: '40px' }}>
          <Icon icon="fa-brands:twitter" style={{ color: '#fff', fontSize: '18px' }} />
        </Link>
        <Link to="#" style={{ backgroundColor: '#D2538C', width: '40px', height: '40px' }}>
          <Icon icon="fa-brands:instagram" style={{ color: '#fff', fontSize: '18px' }} />
        </Link>
      </div>
    </div>
  );
}
