
import { useEffect } from 'react';
import { pageTitle } from '../../helpers/PageTitle';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import Spacing from '../Spacing';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/user/userSlice';
import { useNavigate } from 'react-router-dom';

const healthcare = [
    {
        title: '24/7 Specialist Access',
        subTitle: 'Unlimited online consultations with a wide range of healthcare professionals.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Unlimited In-Person Consults',
        subTitle: 'Free, unlimited face-to-face doctor visits across various specialties.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Dedicated Family Doctor',
        subTitle: 'Personalized care from a chosen family doctor for tailored healthcare.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Exclusive Wellness Programs',
        subTitle: 'Access to curated health programs, including early disease detection and wellness advice.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Unlimited In-Person Doctor Consultations',
        subTitle: 'Unlimited yearly consultations with nearby doctors or your family physician, covering various specialties at no cost.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Personalized Doctor Selection',
        subTitle: 'Suggest your preferred doctor and facility for inclusion, unless they opt out.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Your Wellbeing Matters',
        subTitle: 'We prioritize your physical, mental health, and overall wellbeing for a fulfilling life.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Empowering Middle-Aged Wellness',
        subTitle: 'Tailored tips for better balance, agility, and strength, reducing fall risks.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Efficient Health Solutions',
        subTitle: 'Save time and money, focus on essentials, access curated health programs.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Early Genetic Discovery',
        subTitle: 'Identify disease risks, get personalized lifestyle advice. (Extra charges apply)',
        iconUrl: '/images/icons/calendar_white.svg'
    }
];

const complementary = [
    {
        title: 'Extensive Insurance Coverage',
        subTitle: 'Comprehensive health and accident insurance with added benefits and coverage.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Exclusive Insurance for Subscribers',
        subTitle: 'Free: Up to 30 Lakhs health insurance & 10 Lakhs personal accident coverage.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Coverage: 91 Days to 65 Years',
        subTitle: 'Insurance available for individuals aged 91 days to 65 years.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'No Medical Check-up Needed',
        subTitle: 'Onboard based on a Good Health Declaration, no check-ups required.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Day 1 Pre-existing Coverage',
        subTitle: 'Day 1 coverage for pre-existing conditions, with waived waiting periods.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Provide Ambulance Coverage',
        subTitle: 'Receive ambulance coverage up to Rs 5000 per person.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Ayush Treatment: Up to 25% FSI',
        subTitle: 'Coverage for Ayush treatments, up to 25% of FSI in recognized hospitals.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Pre & Post Hospitalisation',
        subTitle: 'Receive coverage for medical expenses incurred before and after hospitalisation.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'COVID Home Care',
        subTitle: 'Access home-based treatment for COVID-19, ensuring comfort and safety during recovery',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: '80D Exemption Benefit',
        subTitle: 'Benefit from tax exemption under Section 80D for your insurance premiums.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Weekly Expert Knowledge Sessions',
        subTitle: 'Join free weekly sessions led by experts in nutrition, lifestyle optimization, and mindfulness',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Weekly Health & Fitness Sessions',
        subTitle: 'Free doctor talks, dietitian sessions, pilates/ergonomics webinars, virtual fitness.',
        iconUrl: '/images/icons/calendar_white.svg'
    }
];

const valueAdded = [
    {
        title: 'Critical Illness Coverage',
        subTitle: 'Rs 5 lakh payout for 15 critical diseases. Protect your health and finances.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Hospital Stay Cash Benefit',
        subTitle: 'Rs 3000/- daily for stays over 24 hours, up to 90 days, alongside insurance.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Parental Counselling Services',
        subTitle: 'Expert support for confident parenting.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Relationship Counselling',
        subTitle: 'Strengthen bonds and resolve conflicts with professional guidance.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Your Security, Our Priority',
        subTitle: 'We safeguard your privacy—no data sales, no compromises.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Exclusive Discounts Delivered',
        subTitle: 'Enjoy special savings on a variety of products and services, delivered straight to you.',
        iconUrl: '/images/icons/calendar_white.svg'
    }
];

export default function Benefits() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);

    useEffect(() => {
        if (window.location.hash) {
            var id = window.location.hash.slice(1);
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [])

    const handleGetStarted = () => {
        if (user?.success && user?.user?.token) {
            dispatch(logoutUser());
        }
        localStorage.setItem('popup', 'true');
        navigate('/')
    }

    pageTitle('Benefits');

    return (
        <>
            <Spacing md="170" />
            <div className="container">
                <div className="cs_banner_text row">
                    <div className='col-xl-6 col-md-12'>
                        <h2 className="cs_banner_title cs_fs_72">Find the Right Plan for You</h2>
                        <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
                            Explore Our Membership Options and Start Your Journey to Better Health
                        </p>
                    </div>
                    <div className='col-xl-6 col-md-12 text-center'>
                        <Spacing md="50" lg="50" xl="100" />
                        <div className={`cs_btn cs_style_1`} onClick={handleGetStarted}>
                            <span>Get Started</span>
                            <i>
                                <img src="images/icons/arrow_white.svg" alt="Icon" />
                                <img src="images/icons/arrow_white.svg" alt="Icon" />
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            <div id='healthcare'>
                <Spacing md="100" />
                <DepartmentSectionStyle2
                    sectionTitle="Your Benefits"
                    sectionTitleUp="Healthcare Services"
                    data={healthcare}
                    handleGetStarted={handleGetStarted}
                />
            </div>
            <div id='complementary'>
                <Spacing md="100" />
                <DepartmentSectionStyle2
                    sectionTitle="Your Benefits"
                    sectionTitleUp="Complementary Services"
                    data={complementary}
                    handleGetStarted={handleGetStarted}
                />
            </div>
            <div id='valueAdded'>
                <Spacing md="100" />
                <DepartmentSectionStyle2
                    sectionTitle="Your Benefits"
                    sectionTitleUp="Value Added Services"
                    data={valueAdded}
                    handleGetStarted={handleGetStarted}
                />
            </div>
            <Spacing md="100" />
            <div className="container">
                <div className="cs_banner_text row">
                    <div className='col-xl-6 col-md-12'>
                        <h2 className="cs_banner_title cs_fs_72">Find the Right Plan for You</h2>
                        <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
                            Explore Our Membership Options and Start Your Journey to Better Health
                        </p>
                    </div>
                    <div className='col-xl-6 col-md-12 text-center'>
                        <Spacing md="50" lg="50" xl="100" />
                        <div className={`cs_btn cs_style_1`} onClick={handleGetStarted}>
                            <span>Get Started</span>
                            <i>
                                <img src="images/icons/arrow_white.svg" alt="Icon" />
                                <img src="images/icons/arrow_white.svg" alt="Icon" />
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}