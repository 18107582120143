import React from 'react';
import { Icon } from '@iconify/react';
import { WEBSITE_URL } from '../../config/apiUrls';

export default function IconBoxStyle5({ item }) {
  return (
    <div className="cs_iconbox cs_style_5 cs_white_bg cs_radius_15">
      <div className="cs_iconbox_icon cs_center cs_accent_bg cs_radius_15">
        <img src={'/images/icons/award.svg'} alt="Icon" />
      </div>
      <h2 className="cs_iconbox_title m-0 cs_fs_20 cs_medium">
        {item?.unique_id}
        {item?.payment_status != 'SUCCESS' &&
          <h6 className='text-danger'>Payment Failed</h6>
        }
      </h2>
      {item?.zoho_invoice_id ? (
        <a target='_blank' href={WEBSITE_URL + 'zoho/invoices/print/' + item?.zoho_invoice_id}
          style={{ border: '1px solid #D2538C', padding: '5px 10px', borderRadius: '10px' }}>
          <span>View</span> <Icon icon="ant-design:eye-twotone" style={{ fontSize: '20px' }} />
        </a>
      ) : (
        <p>Invoice not Generated</p>
      )}
    </div>
  );
}
